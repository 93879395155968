exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-candidates-2-js": () => import("./../../../src/pages/candidates2.js" /* webpackChunkName: "component---src-pages-candidates-2-js" */),
  "component---src-pages-candidates-3-js": () => import("./../../../src/pages/candidates3.js" /* webpackChunkName: "component---src-pages-candidates-3-js" */),
  "component---src-pages-candidates-js": () => import("./../../../src/pages/candidates.js" /* webpackChunkName: "component---src-pages-candidates-js" */),
  "component---src-pages-candidateslanding-js": () => import("./../../../src/pages/candidateslanding.js" /* webpackChunkName: "component---src-pages-candidateslanding-js" */),
  "component---src-pages-charter-js": () => import("./../../../src/pages/charter.js" /* webpackChunkName: "component---src-pages-charter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-newspage-js": () => import("./../../../src/templates/newspage.js" /* webpackChunkName: "component---src-templates-newspage-js" */),
  "component---src-templates-newspost-js": () => import("./../../../src/templates/newspost.js" /* webpackChunkName: "component---src-templates-newspost-js" */)
}

